import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { User } from '../users/user.model';
import { CigpStaff } from '../cigp-staff/cigp-staff.model';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { ToastComponent } from '../shared/toast.component';
import { Account } from './account.model';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { MappingsService } from '../mappings/mappings.service';
import { UsersService } from '../users/users.service';
import { CigpStaffService } from '../cigp-staff/cigp-staff.service';
import { CigpStaffAvatarCardComponent } from '../cigp-staff/cigp-staff-avatar-card.component';
import { USER_TYPE_CLIENT, USER_TYPE_STAFF } from '../shared/constants';
import { combineLatest, tap } from 'rxjs';
import { AccountMappingsResponse } from '../mappings/mapping.model';
import { ChipListModule, ClickEventArgs } from '@syncfusion/ej2-angular-buttons';
import { orderBy } from 'lodash-es';
import { BasicUser } from '../shared/basic-user.model';

interface EditAccountMappingsDialogFormConfig {
  clients: FormControl<User[] | null>;
  staffs: FormControl<CigpStaff[] | null>;
}

@Component({
  selector: 'eaa-edit-account-mappings-dialog',
  imports: [
    ChipListModule,
    CigpStaffAvatarCardComponent,
    DialogModule,
    MatProgressSpinnerModule,
    MultiSelectModule,
    ReactiveFormsModule,
    ToastComponent
  ],
  template: `
    <ejs-dialog #dgMappings [showCloseIcon]="true" [isModal]="true" [visible]="false" width="1000px">
      <ng-template #content>
        <form [formGroup]="form">
          @if (editMode) {
            <!-- Clients -->
            <ejs-multiselect
              [dataSource]="clients"
              [allowFiltering]="true"
              [ignoreAccent]="true"
              [fields]="{ text: 'displayName', value: 'email' }"
              placeholder="Select client(s)"
              formControlName="clients"
              [allowObjectBinding]="true"
              (filtering)="onFilteringClients($event)"
              floatLabelType="Auto"
            >
              <ng-template #itemTemplate="" let-data="">
                <div class="d-flex justify-content-between">
                  <div>{{ data.displayName }}</div>
                  <div class="d-flex flex-column align-items-end">
                    <small>{{ data.email }}</small>
                  </div>
                </div>
              </ng-template>
            </ejs-multiselect>

            <!-- Staffs -->
            <ejs-multiselect
              [dataSource]="staffs"
              [allowFiltering]="true"
              [ignoreAccent]="true"
              [fields]="{ text: 'displayName', value: 'email' }"
              placeholder="Select staff(s)"
              formControlName="staffs"
              floatLabelType="Auto"
              [allowObjectBinding]="true"
              (filtering)="onFilteringStaffs($event)"
            >
              <ng-template #itemTemplate="" let-data="">
                <div class="p-1">
                  <eaa-cigp-staff-avatar-card [user]="data" [showName]="true"></eaa-cigp-staff-avatar-card>
                </div>
              </ng-template>
              <ng-template #valueTemplate="" let-data="">
                <eaa-cigp-staff-avatar-card
                  [user]="data"
                  size="18px"
                  [showInitials]="true"
                ></eaa-cigp-staff-avatar-card>
              </ng-template>
            </ejs-multiselect>
          } @else {
            <div class="card mb-2">
              <div class="card-body">
                <h6>{{ selectedClients.length }} client(s)</h6>
                <ejs-chiplist cssClass="e-outline" (click)="copyUserDetails($event)">
                  <e-chips>
                    @for (client of selectedClients; track $index) {
                      <e-chip [text]="client.displayName" [value]="client" trailingIconCss="e-icons e-copy" />
                    }
                  </e-chips>
                </ejs-chiplist>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h6>{{ selectedStaffs.length }} staff(s)</h6>
                <ejs-chiplist cssClass="e-outline" (click)="copyUserDetails($event)">
                  <e-chips>
                    @for (staff of selectedStaffs; track $index) {
                      <e-chip [text]="staff.displayName" [value]="staff" trailingIconCss="e-icons e-copy" />
                    }
                  </e-chips>
                </ejs-chiplist>
              </div>
            </div>
          }
        </form>
      </ng-template>
      <ng-template #header
        >{{ mappingsCount }} mapping(s) of {{ account?.shortName }} ({{ account?.name }})
      </ng-template>
      <ng-template #footerTemplate>
        @if (editMode) {
          <div class="btn-group">
            <button class="btn btn-outline-secondary" (click)="close()">CANCEL</button>
            <button class="btn btn-primary" (click)="saveMappingsChanges()">SAVE</button>
          </div>
        } @else {
          <div class="btn-group">
            <button class="btn btn-outline-info" (click)="copyAllUsersDetails()">
              COPY ALL <span class="e-icons e-copy"></span>
            </button>
            <button class="btn btn-outline-danger" (click)="editMode = true">EDIT</button>
            <button class="btn btn-outline-secondary" (click)="close()">CLOSE</button>
          </div>
        }
      </ng-template>
    </ejs-dialog>

    <eaa-toast #toastEditAccountMappingsAction [showCloseButton]="false">
      <div id="title">PROCESSING</div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          {{ processingMessage }}
        </div>
      </div>
    </eaa-toast>
  `
})
export class EditAccountMappingsDialogComponent implements OnChanges, OnInit {
  public clients: User[] | null = null;
  public staffs: CigpStaff[] | null = null;
  public form: FormGroup = new FormGroup({});
  public processingMessage = '';
  public editMode = false;

  @Input()
  public accountId: number | null | undefined = null;

  @Input()
  public account: Account | null = null;

  @ViewChild('dgMappings')
  public dialog: DialogComponent | null = null;

  @ViewChild('toastEditAccountMappingsAction')
  public toastEditAccountMappingsAction?: ToastComponent;

  constructor(
    private mappingsService: MappingsService,
    private usersService: UsersService,
    private cigpStaffService: CigpStaffService,
    private fb: FormBuilder
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['accountId']?.currentValue !== changes['accountId']?.previousValue) {
      this.form.disable();
      this.form.reset();

      if (this.accountId) {
        combineLatest([
          this.mappingsService.getAccountsMappedUserEmails$(USER_TYPE_CLIENT, this.accountId),
          this.mappingsService.getAccountsMappedUserEmails$(USER_TYPE_STAFF, this.accountId)
        ]).subscribe(([clientsResponse, staffsResponse]) => {
          this.form
            .get('clients')
            ?.setValue(
              clientsResponse && clientsResponse.length > 0
                ? this.clients?.filter((c) =>
                    clientsResponse[0].mappedUserEmails?.map((e) => e.toLowerCase()).includes(c.email.toLowerCase())
                  )
                : []
            );
          this.form
            .get('staffs')
            ?.setValue(
              staffsResponse && staffsResponse.length > 0
                ? this.staffs?.filter((s) =>
                    staffsResponse[0].mappedUserEmails?.map((e) => e.toLowerCase()).includes(s.email.toLowerCase())
                  )
                : []
            );
          this.form.enable();
        });
      }
    }
  }

  public ngOnInit(): void {
    this.usersService.getUsersFromCache$().subscribe((users) => (this.clients = users));
    this.cigpStaffService.getEntitledStaffs$().subscribe((staffs) => (this.staffs = staffs));

    this.form = this.fb.group<EditAccountMappingsDialogFormConfig>({
      clients: this.fb.control<User[]>([]),
      staffs: this.fb.control<CigpStaff[]>([])
    });
  }

  public get selectedClients(): User[] {
    return orderBy((this.form.get('clients')?.value as User[] | null) || [], 'displayName');
  }

  public get selectedStaffs(): CigpStaff[] {
    return orderBy((this.form.get('staffs')?.value as CigpStaff[] | null) || [], 'displayName');
  }

  public get mappingsCount(): number {
    return this.selectedClients.length + this.selectedStaffs.length;
  }

  public show(): void {
    this.dialog?.show();
  }

  public close(): void {
    this.editMode = false;
    this.dialog?.hide();
  }

  public saveMappingsChanges(): void {
    this.editMode = false;

    if (!this.accountId || !this.account || this.form.invalid) {
      return;
    }

    this.form.disable();
    this.toastEditAccountMappingsAction?.show();

    const selectedClients = (this.form.get('clients')?.value as User[] | null) || [];
    const selectedStaffs = (this.form.get('staffs')?.value as CigpStaff[] | null) || [];

    this.processingMessage = `Saving ${selectedClients.length} client mappings`;

    const upsertClients$ = this.mappingsService
      .upsertAccountMappings$({
        account: {
          id: this.account.id,
          name: this.account.name
        },
        userEmails: selectedClients.map((m) => m.email),
        userType: USER_TYPE_CLIENT
      })
      .pipe(tap(() => (this.processingMessage = `Saving ${selectedStaffs.length} staff mappings`)));

    const upsertStaffs$ = this.mappingsService.upsertAccountMappings$({
      account: {
        id: this.account.id,
        name: this.account.name
      },
      userEmails: selectedStaffs.map((m) => m.email),
      userType: USER_TYPE_STAFF
    });

    combineLatest([upsertClients$, upsertStaffs$]).subscribe({
      next: ([clientsResponse, staffsResponse]) => this.handleAfterExecute(true, clientsResponse, staffsResponse),
      error: () => this.handleAfterExecute(false)
    });
  }

  public onFilteringClients(e: any): void {
    e.preventDefaultAction = true;

    const predicate = new Predicate('displayName', 'contains', e.text, true, true)
      .or('email', 'contains', e.text, true, true)
      .or('clientCode', 'contains', e.text, true, true);

    let query = new Query();
    query = e.text != '' ? query.where(predicate) : query;

    e.updateData(this.clients, query);
  }

  public onFilteringStaffs(e: any): void {
    e.preventDefaultAction = true;

    const predicate = new Predicate('displayName', 'contains', e.text, true, true)
      .or('email', 'contains', e.text, true, true)
      .or('initials', 'contains', e.text, true, true);

    let query = new Query();
    query = e.text != '' ? query.where(predicate) : query;

    e.updateData(this.staffs, query);
  }

  public copyUserDetails(e: ClickEventArgs) {
    const user = e.data ? ((e.data as any)['value'] as BasicUser) : null;

    if (user) {
      navigator.clipboard.writeText(`${user.displayName} (${user.email})`);
    }
  }

  public copyAllUsersDetails() {
    const clients = this.selectedClients as BasicUser[];

    let returnValue = '';

    if (clients.length > 0) {
      returnValue += 'Clients:\n' + clients.map((c) => `${c.displayName} (${c.email})`).join('\n') + '\n\n';
    }

    const staffs = this.selectedStaffs as BasicUser[];

    if (staffs.length > 0) {
      returnValue += 'Staffs:\n' + staffs.map((s) => `${s.displayName} (${s.email})`).join('\n');
    }

    navigator.clipboard.writeText(returnValue.trim());
  }

  private handleAfterExecute(
    success: boolean,
    clientsResponse?: AccountMappingsResponse,
    staffsResponse?: AccountMappingsResponse
  ): void {
    if (success && clientsResponse && staffsResponse) {
      this.processingMessage = `Mappings saved.`;

      this.form
        .get('clients')
        ?.setValue(
          this.clients?.filter((c) =>
            clientsResponse.mappedUserEmails?.map((e) => e.toLowerCase()).includes(c.email.toLowerCase())
          )
        );
      this.form
        .get('staffs')
        ?.setValue(
          this.staffs?.filter((s) =>
            staffsResponse.mappedUserEmails?.map((e) => e.toLowerCase()).includes(s.email.toLowerCase())
          )
        );

      setTimeout(() => {
        this.resetToast();
      }, 1500);
    } else {
      this.resetToast();
    }

    this.form.enable();
    this.dialog?.hide();
  }

  private resetToast() {
    this.toastEditAccountMappingsAction?.hide();
    this.processingMessage = '';
  }
}
