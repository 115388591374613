import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AuditTrailUserSignin } from './audit-trail-user-signin.model';
import { showErrorDialog } from '../../shared/utils';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailUserSigninsService {
  private apiEndpoint = `${environment.apiEndpoint}/api/user-signins`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog
  ) {}

  public getAuditTrailMonthlyStatementsDistributionItems$(
    startDate: Date,
    endDate: Date,
    email: string | null
  ): Observable<AuditTrailUserSignin[]> {
    return this.http
      .get<AuditTrailUserSignin[]>(`${this.apiEndpoint}`, {
        params: this.computeQueryParams(startDate, endDate, email)
      })
      .pipe(
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        }),
        map((items: AuditTrailUserSignin[]) => items.map((i) => ({ ...i, time: moment(i.time).toDate() })))
      );
  }

  private computeQueryParams(startDate: Date, endDate: Date, email: string | null) {
    const params: { [param: string]: string } = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    };

    if (email) {
      params['email'] = email;
    }

    return params;
  }
}
