import { Component } from '@angular/core';
import { EaccessAuthService } from '../auth/eaccess-auth.service';
import { ROLE_AUDIT_TRAIL_MONTHLY_STATEMENTS_DISTRIBUTION_READ, ROLE_COMPLIANCE } from '../shared/constants';

@Component({
  selector: 'eaa-can-view-audit-trail',
  imports: [],
  template: `
    @if (canView) {
      <ng-content></ng-content>
    }
  `
})
export class CanViewAuditTrailComponent {
  public canView: boolean | null = null;

  constructor(private authService: EaccessAuthService) {}

  ngOnInit(): void {
    this.canView = this.authService.hasOneOfRoles([
      ROLE_AUDIT_TRAIL_MONTHLY_STATEMENTS_DISTRIBUTION_READ,
      ROLE_COMPLIANCE
    ]);
  }
}
