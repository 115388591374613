import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from './users.service';
import { User, UserToCreate, UserToUpdate } from './user.model';
import {
  EditService,
  ExcelExportService,
  FilterService,
  GridComponent,
  GridModule,
  PageService,
  SearchService,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { NGXLogger } from 'ngx-logger';
import { FormsModule } from '@angular/forms';
import { Dialog, DialogUtility } from '@syncfusion/ej2-popups';
import { ToastComponent } from '../shared/toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import {
  ID_EDIT_MAPPINGS,
  ID_GO_TO_DOCUMENTS,
  ID_GO_TO_MESSAGES,
  ID_RESET_2FA,
  TOOLBAR_ITEM_DOCUMENTS,
  TOOLBAR_ITEM_MAPPINGS,
  TOOLBAR_ITEM_MESSAGES,
  TOOLBAR_ITEM_RESET_2FA,
  USER_TYPE_CLIENT
} from '../shared/constants';
import { EditUserMappingsDialogComponent } from './edit-user-mappings-dialog.component';
import { ResetUserTwofaDialogComponent } from './reset-user-twofa-dialog.component';
import { environment } from '../../environments/environment';

interface EnrichedUser extends User {
  mappingsCount?: number;
  twoFaRegistered?: boolean;
}

@Component({
  selector: 'eaa-clients-list',
  imports: [
    EditUserMappingsDialogComponent,
    FormsModule,
    GridModule,
    MatProgressSpinnerModule,
    ResetUserTwofaDialogComponent,
    ToastComponent
  ],
  providers: [EditService, ExcelExportService, FilterService, PageService, SearchService, ToolbarService],
  template: `
    <ejs-grid
      #grdClients
      id="grdClients"
      [dataSource]="users"
      [allowPaging]="true"
      [pageSettings]="{ pageSize: 50, pageSizes: ['10', '20', '50', '100', '250', 'All'] }"
      [allowFiltering]="true"
      [filterSettings]="{ enableCaseSensitivity: false, ignoreAccent: true, type: 'Excel' }"
      [allowExcelExport]="true"
      [allowTextWrap]="true"
      [toolbar]="[
        'Add',
        'Delete',
        'Cancel',
        toolbarItemDocuments,
        toolbarItemMessages,
        toolbarItemMappings,
        toolbarItemReset2Fa,
        'ExcelExport',
        'Search'
      ]"
      (toolbarClick)="toolbarClick($event)"
      [editSettings]="{
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: 'Dialog',
        showDeleteConfirmDialog: true
      }"
      (actionComplete)="onActionComplete($event)"
      (rowSelected)="onRowSelected($event)"
      (rowDeselected)="onRowSelected($event)"
    >
      <e-columns>
        <e-column field="id" headerText="#" [isPrimaryKey]="true" [visible]="false" />
        <e-column field="displayName" headerText="Name" [validationRules]="{ required: true, minLength: 3 }" />
        <e-column
          field="email"
          headerText="Email"
          [validationRules]="{
            required: true,
            email: emailValidationFn,
            noCigpEmail: [noCigpEmailValidationFn, 'CIGP staff should use CIGP Staff login instead']
          }"
        />
        <e-column
          field="phone"
          headerText="Phone"
          [validationRules]="{ phone: [phoneValidationFn, 'Please enter a valid phone number'] }"
        />
        <e-column field="clientCode" headerText="Client Code" [validationRules]="{ maxLength: 5 }" />
        <e-column field="crmPersonId" headerText="CRM Person ID" [validationRules]="{ min: 1 }">
          <ng-template #template let-data>
            @if (data.crmPersonId) {
              <a target="_blank" [href]="computeLinkToCrmPerson(data.crmPersonId)" title="View in CRM">{{
                data.crmPersonId
              }}</a>
            }
          </ng-template>
        </e-column>
        <e-column
          field="optOutOfEmailNotification"
          headerText="Opt out of Email Notification"
          [displayAsCheckBox]="true"
          editType="booleanedit"
        />
        <e-column field="mappingsCount" headerText="Mappings" type="number" [allowEditing]="false" />
        <e-column
          headerText="2FA Registered"
          [allowEditing]="false"
          field="twoFaRegistered"
          [displayAsCheckBox]="true"
        />
      </e-columns>
    </ejs-grid>

    <eaa-edit-user-mappings-dialog
      #dgUserMappings
      [userDisplayName]="selectedUser?.displayName"
      [userEmail]="selectedUser?.email"
      [userType]="userType"
    ></eaa-edit-user-mappings-dialog>

    <eaa-reset-user-twofa-dialog
      #dgReset2Fa
      [userDisplayName]="selectedUser?.displayName"
      [userEmail]="selectedUser?.email"
      [userId]="selectedUser?.id"
      [deviceRegistrationIds]="selectedUser?.twoFaDeviceRegistrations"
      (resetComplete)="setUsers()"
    />

    <eaa-toast #toastClientAction [showCloseButton]="false">
      <div id="title">PROCESSING</div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          {{ processingMessage }}
        </div>
      </div>
    </eaa-toast>
  `
})
export class ClientsListComponent implements OnInit {
  public users: EnrichedUser[] | null = null;
  public processingMessage = '';
  public toolbarItemDocuments = TOOLBAR_ITEM_DOCUMENTS;
  public toolbarItemMappings = TOOLBAR_ITEM_MAPPINGS;
  public toolbarItemMessages = TOOLBAR_ITEM_MESSAGES;
  public toolbarItemReset2Fa = TOOLBAR_ITEM_RESET_2FA;
  public userType = USER_TYPE_CLIENT;
  private confirmDialog: Dialog | null = null;

  public emailValidationFn: (args: { [key: string]: string }) => boolean = (args: { [key: string]: string }) => {
    return /^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$/.test(args['value']);
  };

  public noCigpEmailValidationFn: (args: { [key: string]: string }) => boolean = (args: { [key: string]: string }) => {
    return !args['value'].toLowerCase().endsWith('@cigp.com');
  };

  public phoneValidationFn: (args: { [key: string]: string }) => boolean = (args: { [key: string]: string }) => {
    return /^\+[1-9]\d{1,14}$/.test(args['value']);
  };

  @ViewChild('grdClients')
  public grid?: GridComponent;

  @ViewChild('toastClientAction')
  public toastClientAction?: ToastComponent;

  @ViewChild('dgUserMappings')
  public editUserMappingsDialog: EditUserMappingsDialogComponent | null = null;

  @ViewChild('dgReset2Fa')
  public resetUserTwofaDialog: ResetUserTwofaDialogComponent | null = null;

  constructor(
    private usersService: UsersService,
    private logger: NGXLogger,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.setUsers();
  }

  public computeLinkToCrmPerson(crmPersonId: number): string {
    return `${environment.crmEndpoint}/persons/${crmPersonId}/view/basic-information`;
  }

  public toolbarClick(args: ClickEventArgs): void {
    if (args.item.id === 'grdClients_excelexport') {
      this.grid?.excelExport();
    } else if (args.item.id === ID_EDIT_MAPPINGS) {
      this.editUserMappingsDialog?.show();
    } else if (args.item.id === ID_GO_TO_DOCUMENTS) {
      this.router.navigate(['/users', 'clients', (this.grid?.getSelectedRecords()[0] as User).id, 'documents']);
    } else if (args.item.id === ID_GO_TO_MESSAGES) {
      this.router.navigate(['/users', 'clients', (this.grid?.getSelectedRecords()[0] as User).id, 'messages']);
    } else if (args.item.id === ID_RESET_2FA) {
      this.resetUserTwofaDialog?.show();
    }
  }

  public onActionComplete(args: any): void {
    if (args.name === 'actionComplete' && args.action === 'add' && args.requestType === 'save') {
      this.addUser(args.data);
    } else if (args.name === 'actionComplete' && args.action === 'edit' && args.requestType === 'save') {
      this.updateUser(args.data);
    } else if (args.name === 'actionComplete' && args.requestType === 'delete') {
      this.deleteUser(args.data[0]);
    } else if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog = (args as any)['dialog'];
      dialog.showCloseIcon = false;
      dialog.width = 800;
      dialog.header = args.requestType === 'beginEdit' ? 'Update ' + (args.rowData as User).displayName : 'New user';
    }
  }

  public onRowSelected(args: any): void {
    if (args.data) {
      this.grid?.enableToolbarItems(
        [ID_EDIT_MAPPINGS, ID_GO_TO_DOCUMENTS, ID_GO_TO_MESSAGES],
        args.name === 'rowSelected'
      );

      this.grid?.enableToolbarItems(['grdClients_delete'], args.data['mappingsCount'] === 0);
      this.grid?.enableToolbarItems([ID_RESET_2FA], !!args.data['twoFaRegistered']);
    }
  }

  public get selectedUser(): User | null {
    return this.grid?.getSelectedRecords()[0] as User;
  }

  private addUser(userToAdd: UserToCreate) {
    this.logger.log('Adding new user', userToAdd);

    this.confirmDialog = DialogUtility.confirm({
      title: 'ADD USER',
      content: `Are you sure you want to add user ${userToAdd.displayName} (${userToAdd.email})?`,
      okButton: {
        text: 'CREATE',
        click: () => {
          this.confirmDialog?.hide();
          this.processingMessage = `Creating user ${userToAdd.displayName}...`;
          this.toastClientAction?.show();
          this.usersService.createUser$(userToAdd).subscribe({
            next: () => this.handleAfterExecute(true),
            error: () => this.handleAfterExecute(false)
          });
        }
      },
      cancelButton: {
        text: 'CANCEL',
        click: () => {
          this.setUsers();
          this.confirmDialog?.hide();
        }
      },
      position: { X: 'center', Y: 'center' }
    });
  }

  private updateUser(userToUpdate: User) {
    this.logger.log('Updating user', userToUpdate);

    this.confirmDialog = DialogUtility.confirm({
      title: 'UPDATE USER',
      content: `Are you sure you want to update user ${userToUpdate.displayName}?`,
      okButton: {
        text: 'UPDATE',
        click: () => {
          this.confirmDialog?.hide();
          this.processingMessage = `Updating user ${userToUpdate.displayName}...`;
          this.toastClientAction?.show();
          this.usersService.updateUser$(userToUpdate.id, userToUpdate as UserToUpdate).subscribe({
            next: () => this.handleAfterExecute(true),
            error: () => this.handleAfterExecute(false)
          });
        }
      },
      cancelButton: {
        text: 'CANCEL',
        click: () => {
          this.setUsers();
          this.confirmDialog?.hide();
        }
      },
      position: { X: 'center', Y: 'center' }
    });
  }

  private deleteUser(user: User) {
    this.logger.log('Deleting user', user);

    this.processingMessage = `Deleting user ${user.displayName}...`;
    this.toastClientAction?.show();
    this.usersService.deleteUser$(user.id).subscribe({
      next: () => this.handleAfterExecute(true),
      error: () => this.handleAfterExecute(false)
    });
  }

  private handleAfterExecute(success: boolean): void {
    if (success) {
      this.processingMessage = `Success.`;
      setTimeout(() => {
        this.resetToast();
      }, 1500);
    } else {
      this.resetToast();
    }

    this.setUsers();
  }

  private resetToast() {
    this.toastClientAction?.hide();
    this.processingMessage = '';
  }

  public setUsers(): void {
    this.usersService.getUsersFromCache$().subscribe((users) => {
      this.users = users.map((user) => ({
        ...user,
        twoFaRegistered: user.twoFaDeviceRegistrations && user.twoFaDeviceRegistrations.length > 0
      }));
      this.countMappings();
      this.grid?.refresh();
    });
  }

  private countMappings(): void {
    this.usersService.countMappingsByUser$().subscribe((mappingsCount) => {
      if (this.users && this.users.length > 0) {
        this.users = this.users.map((user) => ({
          ...user,
          mappingsCount: mappingsCount.find((m) => m.email === user.email)?.mappingsCount
        }));
      }
    });
  }
}
