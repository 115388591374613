import { FEEDBACK_FORM, USER_MANUAL } from '../app/shared/constants';

export const environment = {
  env: 'staging',
  consentScopes: ['api://c2cc72ca-92ce-4af5-b9f4-ab3106962cc8/user_impersonation'],
  apiEndpoint: 'https://cigpeaccessapistaging2-cedsdya5hudxepdb.z01.azurefd.net',
  crmEndpoint: 'https://crm.cigp.app',
  graphEndpoint: 'https://graph.microsoft.com/v1.0',
  clientId: 'b9663bb6-9bd8-4612-9a5c-cfc3c3d8d0c5',
  tenant: '6f41eebe-f4f6-41f6-9d01-03d70104f18c',
  userManual: USER_MANUAL,
  feedbackForm: FEEDBACK_FORM
};
