import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { AccountsService } from '../accounts/accounts.service';
import { Account } from '../accounts/account.model';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastComponent } from '../shared/toast.component';
import { MappingsService } from '../mappings/mappings.service';
import { UserMappingsResponse } from '../mappings/mapping.model';
import { ChipListModule, ClickEventArgs } from '@syncfusion/ej2-angular-buttons';
import { orderBy } from 'lodash-es';

interface EditUserMappingsDialogFormConfig {
  accounts: FormControl<Account[] | null>;
}

@Component({
  selector: 'eaa-edit-user-mappings-dialog',
  imports: [
    ChipListModule,
    DialogModule,
    MatProgressSpinnerModule,
    MultiSelectModule,
    ReactiveFormsModule,
    ToastComponent
  ],
  template: `
    <ejs-dialog #dgMappings [showCloseIcon]="true" [isModal]="true" [visible]="false" width="1000px">
      <ng-template #content>
        <form [formGroup]="form">
          @if (editMode) {
            <ejs-multiselect
              [dataSource]="accounts"
              [allowFiltering]="true"
              [ignoreAccent]="true"
              [fields]="{ text: 'shortName', value: 'id' }"
              placeholder="Select account(s)"
              formControlName="accounts"
              [allowObjectBinding]="true"
              (filtering)="onFilteringPortfolios($event)"
            >
              <ng-template #itemTemplate="" let-data="">
                <div class="d-flex justify-content-between">
                  <div>{{ data.name }}</div>
                  <div class="d-flex flex-column align-items-end">
                    <small>{{ data.shortName }}</small>
                  </div>
                </div>
              </ng-template>
            </ejs-multiselect>
          } @else {
            <ejs-chiplist cssClass="e-outline" (click)="copyAccountDetails($event)">
              <e-chips>
                @for (account of selectedAccounts; track $index) {
                  <e-chip [text]="account.shortName" [value]="account" trailingIconCss="e-icons e-copy" />
                }
              </e-chips>
            </ejs-chiplist>
          }
        </form>
      </ng-template>
      <ng-template #header
        >{{ selectedAccounts.length }} mapping(s) of {{ userDisplayName }} ({{ userEmail }})
      </ng-template>
      <ng-template #footerTemplate>
        @if (editMode) {
          <div class="btn-group">
            <button class="btn btn-outline-secondary" (click)="close()">CANCEL</button>
            <button class="btn btn-primary" (click)="saveMappingsChanges()">SAVE</button>
          </div>
        } @else {
          <div class="btn-group">
            <button class="btn btn-outline-info" (click)="copyAllAccountsDetails()">
              COPY ALL <span class="e-icons e-copy"></span>
            </button>
            <button class="btn btn-outline-danger" (click)="editMode = true">EDIT</button>
            <button class="btn btn-outline-secondary" (click)="close()">CLOSE</button>
          </div>
        }
      </ng-template>
    </ejs-dialog>

    <eaa-toast #toastEditUserMappingsAction [showCloseButton]="false">
      <div id="title">PROCESSING</div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          {{ processingMessage }}
        </div>
      </div>
    </eaa-toast>
  `
})
export class EditUserMappingsDialogComponent implements OnChanges, OnInit {
  public accounts: Account[] | null = null;
  public form: FormGroup = new FormGroup({});
  public processingMessage = '';
  public editMode = false;

  @Input()
  public userDisplayName: string | null | undefined = null;

  @Input()
  public userEmail: string | null | undefined = null;

  @Input()
  public userType: string | null = null;

  @ViewChild('dgMappings')
  public dialog: DialogComponent | null = null;

  @ViewChild('toastEditUserMappingsAction')
  public toastEditUserMappingsAction?: ToastComponent;

  constructor(
    private mappingsService: MappingsService,
    private accountsService: AccountsService,
    private fb: FormBuilder
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['userEmail']?.currentValue !== changes['userEmail']?.previousValue) {
      this.form.disable();
      this.form.reset();

      if (this.userEmail && this.userType) {
        this.mappingsService.getUsersMappedAccountIds$(this.userType, this.userEmail).subscribe((response) => {
          this.form
            .get('accounts')
            ?.setValue(
              response && response.length > 0
                ? this.accounts?.filter((a) => response[0].mappedAccountIds.includes(a.id))
                : []
            );

          this.form.enable();
        });
      }
    }
  }

  public ngOnInit(): void {
    this.accountsService.getAccountsFromCache$().subscribe((accounts) => (this.accounts = accounts));

    this.form = this.fb.group<EditUserMappingsDialogFormConfig>({
      accounts: this.fb.control<Account[]>([])
    });
  }

  public show(): void {
    this.dialog?.show();
  }

  public close(): void {
    this.editMode = false;
    this.dialog?.hide();
  }

  public onFilteringPortfolios(e: any): void {
    e.preventDefaultAction = true;

    const predicate = new Predicate('name', 'contains', e.text, true, true).or(
      'shortName',
      'contains',
      e.text,
      true,
      true
    );

    let query = new Query();
    query = e.text != '' ? query.where(predicate) : query;

    e.updateData(this.accounts, query);
  }

  public get selectedAccounts(): Account[] {
    return orderBy((this.form.get('accounts')?.value as Account[] | null) || [], 'shortName');
  }

  public saveMappingsChanges(): void {
    this.editMode = false;

    if (!this.userEmail || !this.userType || this.form.invalid) {
      return;
    }

    this.form.disable();

    this.processingMessage = `Saving ${this.selectedAccounts.length} mappings`;
    this.toastEditUserMappingsAction?.show();

    this.mappingsService
      .upsertUserMappings$({
        accounts: this.selectedAccounts.map((m) => ({ id: m.id, name: m.name })),
        userEmail: this.userEmail,
        userType: this.userType
      })
      .subscribe({
        next: (response) => this.handleAfterExecute(true, response),
        error: () => this.handleAfterExecute(false)
      });
  }

  public copyAccountDetails(e: ClickEventArgs) {
    const account = e.data ? ((e.data as any)['value'] as Account) : null;

    if (account) {
      navigator.clipboard.writeText(`${account.shortName} - ${account.name}`);
    }
  }

  public copyAllAccountsDetails() {
    const accounts = this.selectedAccounts;

    if (accounts.length > 0) {
      navigator.clipboard.writeText(accounts.map((a) => `${a.shortName} - ${a.name}`).join('\n'));
    }
  }

  private handleAfterExecute(success: boolean, response?: UserMappingsResponse): void {
    if (success && response) {
      this.processingMessage = `Mappings saved.`;

      this.form
        .get('accounts')
        ?.setValue(
          response.mappedAccountIds && response.mappedAccountIds.length > 0
            ? this.accounts?.filter((a) => response.mappedAccountIds.includes(a.id))
            : []
        );

      setTimeout(() => {
        this.resetToast();
      }, 1500);
    } else {
      this.resetToast();
    }

    this.form.enable();
    this.dialog?.hide();
  }

  private resetToast() {
    this.toastEditUserMappingsAction?.hide();
    this.processingMessage = '';
  }
}
