import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditTrailUserSigninsService } from './audit-trail-user-signins.service';
import {
  FilterService,
  GridComponent,
  GridModule,
  PageService,
  SearchService,
  SortService,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import moment from 'moment';
import { Observable } from 'rxjs';
import { AuditTrailUserSignin } from './audit-trail-user-signin.model';
import { AsyncPipe } from '@angular/common';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';

@Component({
  selector: 'eaa-audit-trail-user-signins',
  providers: [FilterService, PageService, SearchService, SortService, ToolbarService],
  imports: [AsyncPipe, DateTimePickerModule, GridModule],
  template: `
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div>
          <h5 class="card-title">USER SIGNIN LOGS</h5>
          <h6 class="card-subtitle text-body-secondary">Audit Trail</h6>
        </div>
        <div class="d-flex align-items-center gap-3">
          <ejs-datetimepicker
            [showClearButton]="false"
            [enabled]="!searching"
            (change)="dateRangeSelected()"
            [max]="today"
            placeholder="Start"
            floatLabelType="Auto"
            [(value)]="start"
            format="dd/MM/yyyy HH:mm:ss z"
            width="260px"
          ></ejs-datetimepicker>
          <ejs-datetimepicker
            [showClearButton]="false"
            [enabled]="!searching"
            (change)="dateRangeSelected()"
            [max]="today"
            placeholder="End"
            floatLabelType="Auto"
            [(value)]="end"
            format="dd/MM/yyyy HH:mm:ss z"
            width="260px"
          ></ejs-datetimepicker>
        </div>
      </div>
      <div class="card-body p-0">
        <ejs-grid
          #grdAuditTrailUserSignins
          id="grdAuditTrailUserSignins"
          [dataSource]="items$ | async"
          [allowPaging]="true"
          [pageSettings]="{ pageSize: 50, pageSizes: ['10', '20', '50', '100', '250', 'All'] }"
          [allowFiltering]="true"
          [filterSettings]="{ enableCaseSensitivity: false, ignoreAccent: true, type: 'Excel' }"
          [allowSorting]="true"
          [allowTextWrap]="true"
          [toolbar]="['Search']"
        >
          <e-columns>
            <e-column field="id" headerText="#" textAlign="Right" width="40px" format="N0" [isPrimaryKey]="true" />
            <e-column field="time" headerText="Time" type="datetime" format="dd/MM/yyyy HH:mm:ss zz" width="100px" />
            <e-column field="displayName" headerText="User" width="180px">
              <ng-template #template let-data>
                <span [title]="data.email">
                  {{ data.displayName }}
                </span>
              </ng-template>
            </e-column>
            <e-column field="location" headerText="Location" width="80px" />
            <e-column field="browser" headerText="Browser" width="120px" />
            <e-column field="operatingSystem" headerText="OS" width="120px" />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  `
})
export class AuditTrailUserSigninsComponent implements OnInit {
  public items$: Observable<AuditTrailUserSignin[]> | null = null;
  public today = new Date();
  public searching = false;
  public start = moment().subtract(7, 'days').toDate();
  public end = new Date();

  @ViewChild('grdAuditTrailUserSignins')
  public grid?: GridComponent;

  constructor(private auditTrailUserSigninsService: AuditTrailUserSigninsService) {}

  public ngOnInit(): void {
    this.setAuditTrailItems();
  }

  public dateRangeSelected(): void {
    this.searching = true;

    this.setAuditTrailItems();

    setTimeout(() => {
      this.searching = false;
    }, 500);
  }

  private setAuditTrailItems() {
    this.items$ = this.auditTrailUserSigninsService.getAuditTrailMonthlyStatementsDistributionItems$(
      this.start,
      this.end,
      null
    );
  }
}
